import React, { useState, useEffect, Fragment } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import {
  Tooltip,
  Select,
  Table,
  Slider,
  Checkbox,
  Spin,
  Pagination,
  Modal,
  Button,
  Empty,
  Input,
} from "antd";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import styled from "styled-components";
import colorPanelService from "../../../services/color-panel.service";

import roundDiamond from "../../../assets/images/daimond-link.jpg";

import Round from "../../../assets/images/choose-setting/round.svg";
import Radiant from "../../../assets/images/choose-setting/Radiant.svg";
import Asscher from "../../../assets/images/choose-setting/Asscher.svg";
import Cushion from "../../../assets/images/choose-setting/Cushion.svg";
import Emerald from "../../../assets/images/choose-setting/Emerald.svg";
import Heart from "../../../assets/images/choose-setting/Heart.svg";
import Marquise from "../../../assets/images/choose-setting/Marquise.svg";
import Oval from "../../../assets/images/choose-setting/Oval.svg";
import Princess from "../../../assets/images/choose-setting/Princess.svg";
import Pear from "../../../assets/images/choose-setting/Radiant.svg";
import Info_icon from "../../../assets/images/info_icon.png";
import Video_icon from "../../../assets/images/icons/video_icon.svg";
import Close from "../../../assets/images/close.png";
import Eye_icon from "../../../assets/images/eye__icon.svg";
import Rectangle from "../../../assets/images/Rectangle.png";
import RoundImage from "../../../assets/images/Round.png";
import PrincessImage from "../../../assets/images/PrincessImage.png";
import NoPreview from "../../../assets/images/no_pre.png";
import { handleViewAddToStudPageDetails } from "../../../actions/selectYourDiamond/selectYourDiamond";
import {
  handleCompleteEarningTwoDiamond,
  handleEditViewShow,
  handleEditViewHide,
} from "../../../actions/chooseYourSetting/chooseYourSettingActions";
import {
  handleViewCompletethisMounting,
  handleHideCompletethisMounting,
  handleHideEditChooseThisMounting,
} from "../../../actions/chooseYourEarning/chooseYourEarningActions";

import {
  handleViewDetailsPage,
  handleComaparePageIds,
  handleSetDiamondDetails,
  handleSetSaveEveryTimeMinedTab,
  handleSetSaveFilterMinedTab,
} from "../../../actions/selectYourDiamond/selectYourDiamond";
import { useDispatch, useSelector } from "react-redux";
import SelectYourDiamondService from "../../../services/select-your-diamond.service";
import {
  handleActiveTab,
  handleAddDiamondViewEdit,
} from "../../../actions/header/headerActions";

const MinedDiamond = ({}) => {
  const { Search } = Input;
  const search = useLocation().search;
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const resetVal = useSelector((state) => state.selectYourDiamondReducer);
  const loginDetails = useSelector((state) => state.loginDetailsReducer);
  const dispatch = useDispatch();
  const [productChange, setProductChange] = useState({});
  const [isAdvance, setIsAdvance] = useState(false);
  const [viewItem, setViewItem] = useState("list");
  const [hideShowFilter, setHideShowFilter] = useState(true);
  const [priceValue, setPriceValue] = useState([0, 9999999]);
  const [caratSlideValue, setCaratSlideValue] = useState([0, 22]);
  const [loading, setLoading] = useState(false);
  const [filterloading, setFilterLoading] = useState(false);
  const [reqDiamondloading, setReqDiamondLoading] = useState(false);
  const [minedList, setMinedList] = useState([]);
  const [isVideo, setIsVideo] = useState(false);
  const [videoImageModal, setVideoImageModal] = useState(false);
  const [selectedRowDetails, setSelectedRowDetails] = useState({});
  const [searchSku, setSearchSku] = useState("");
  const [isFilter, setIsFilter] = useState(false);
  const [selectedCertificates, setSelectedCertificates] = useState([]);
  const [state, setState] = useState({
    productChange: {},
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    Certificates: [],
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [diamondFormData, setDiamondFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    emailAddress: "",
    comments: "",
  });
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [TotalRowCount, setTotalRowCount] = useState(0);
  const [dealerId, setDealerId] = useState();
  const [rowDetails, setRowDetails] = useState({});
  const [filterVals, setFilterVals] = useState({
    Shape: [],
    Cut: [],
    Color: [],
    Clarity: [],
    Polish: [],
    Fluorescence: [],
    Symmetry: [],
    TableMin: 0,
    TableMax: 100,
    DepthMin: 0,
    DepthMax: 100,
    PriceMin: 0,
    PriceMax: 9999999,
    CaratMin: 0,
    CaratMax: 22,
    Certificates: [],
  });
  // const [varColorObj, setVarColorObj] = useState({
  //   callToActionColor: "",
  //   hoverColor: "",
  //   columnHeaderAccentColor: "",
  //   linkColor: "",
  // });
  // const [toggle, setToggle] = useState(true);
  // const previewcolor = useSelector((state) => state.previewColorReducer);
  const [name, setName] = useState("");
  const [isHovering, setIsHovering] = useState(false);
  const [sortColumnName, setSortColumnName] = useState("");
  const [sortType, setSortType] = useState("asc");

  // Options

  const initialOptionsData = {
    studbuilderLinkURL: "",
    internalUseLink: true,
    showOnlyDiamondSearch: true,
    showDealerDiamondsSKU: true,
    showScheduleViewing: true,
    showAddress: true,
    showCallForPrice: true,
    applyGstTax: true,
    gstTaxValue: "",
    showPinterestShare: true,
    showTwitterShare: true,
    showFacebookShare: true,
    showFacebookLike: true,
    showAddToCartButton: true,
    ShoppingCartURL: "",
    showViewCartButton: true,
    ShowInstagramPlus: true,
  };
  const [myOptionsData, setMyOptionsData] = useState(initialOptionsData);

  const handleGetstudBuilderOptiondetails = (id) => {
    let inputData = {
      dealerID: id,
    };

    try {
      SelectYourDiamondService.GetstudBuilderOptiondetails(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData[0];
          if (message == "Success") {
            if (responseData) {
              if (response.data.responseData.length > 0) {
                setMyOptionsData((prevState) => ({
                  ...prevState,
                  studbuilderLinkURL: responseData.studBuilderURL,
                  internalUseLink: responseData.internalLinkSB,
                  showOnlyDiamondSearch: responseData.onlyDiamondSearch,
                  showDealerDiamondsSKU: responseData.stud_ShowSKU,
                  showScheduleViewing: responseData.showScheduleView,
                  showAddress: responseData.showAddresses,
                  showCallForPrice: responseData.callForPricestud,
                  applyGstTax: responseData.showGstPrice,
                  gstTaxValue: responseData.taxRate,
                  showPinterestShare: responseData.showPinterestShare,
                  showTwitterShare: responseData.showTwitterShare,
                  showFacebookShare: responseData.showFacebookShare,
                  showFacebookLike: responseData.showFacebookLike,
                  showAddToCartButton: responseData.showAddToCartButtonStud,
                  ShoppingCartURL: responseData.diamondstudshoppingurl,
                  showViewCartButton: responseData.showViewCartButtonStud,
                  ShowInstagramPlus: responseData.showInstagramShare,
                }));
              }
            }
          } else {
            console.log("Not Binded");
          }
        })
        .catch((error) => {
          console.log(error);
          setMyOptionsData((prevState) => ({
            showOnlyDiamondSearch: false,
          }));
        });
    } catch (error) {
      console.log(error);
      setMyOptionsData((prevState) => ({
        showOnlyDiamondSearch: false,
      }));
    }
  };

  /* Color Individually Start */

  const [isLightHeaderColor, setIsLightHeaderColor] = useState(false);
  const [isLightButtonColor, setIsLightButtonColor] = useState(false);
  const [isLightHoverColor, setIsLightHoverColor] = useState(false);
  const [wrapperLoading, setWrapperLoading] = useState(false);

  const previewcolor = useSelector((state) => state.previewColorReducer);
  const [varColorObj, setVarColorObj] = useState({
    callToActionColor: "",
    hoverColor: "",
    columnHeaderAccentColor: "",
    linkColor: "",
  });
  const [toggle, setToggle] = useState(true);
  useEffect(() => {
    if (paramdealerId) {
      if (paramdealerId) {
        handleGetColorsData(paramdealerId);
      }
    }
  }, []); //  }, [previewcolor.toogle]);

  const handleGetColorsData = async (id) => {
    let inputData = {
      dealerID: Number(id),
      moduleName: "StudBuilder",
      hoverEffect: "",
      columnHeaderAccent: "",
      linkColor: "",
      actionButton: "",
    };
    setWrapperLoading(true);
    try {
      await colorPanelService
        .GetHoverEffect(inputData)
        .then((response) => {
          let msg = response.data.message;
          let initialColorObj =
            response.data.responseData.initialHoverEffectList[0];
          let newColorObj =
            response.data.responseData.ringBuilder_HoverEffect_List[0];
          let colorObj = {
            hoverEffect: "",
            columnHeaderAccent: "",
            linkColor: "",
            actionButton: "",
          };

          if (msg == "Success") {
            if (initialColorObj) {
              var tempobj = colorObj;
              tempobj.hoverEffect = newColorObj
                ? newColorObj.hoverEffect == ""
                  ? initialColorObj.hoverEffect
                  : newColorObj.hoverEffect
                : initialColorObj.hoverEffect;
              tempobj.columnHeaderAccent = newColorObj
                ? newColorObj.columnHeaderAccent == ""
                  ? initialColorObj.columnHeaderAccent
                  : newColorObj.columnHeaderAccent
                : initialColorObj.columnHeaderAccent;
              tempobj.linkColor = newColorObj
                ? newColorObj.linkColor == ""
                  ? initialColorObj.linkColor
                  : newColorObj.linkColor
                : initialColorObj.linkColor;
              tempobj.actionButton = newColorObj
                ? newColorObj.actionButton == ""
                  ? initialColorObj.actionButton
                  : newColorObj.actionButton
                : initialColorObj.actionButton;
              debugger;
              console.log(tempobj);
              console.log(initialColorObj);
              if (tempobj) {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: tempobj.actionButton,
                  hoverColor: tempobj.hoverEffect,
                  columnHeaderAccentColor: tempobj.columnHeaderAccent,
                  linkColor: tempobj.linkColor,
                });
                lightOrDark(tempobj.columnHeaderAccent, "header");
                lightOrDark(tempobj.callToActionColor, "button");
                lightOrDark(tempobj.hoverColor, "hover");
              } else {
                setVarColorObj({
                  ...varColorObj,
                  callToActionColor: initialColorObj.actionButton,
                  hoverColor: initialColorObj.hoverEffect,
                  columnHeaderAccentColor: initialColorObj.columnHeaderAccent,
                  linkColor: initialColorObj.linkColor,
                });
                lightOrDark(initialColorObj.columnHeaderAccent, "header");
                lightOrDark(initialColorObj.callToActionColor, "button");
                lightOrDark(initialColorObj.hoverColor, "hover");
              }
            }
          }
          setWrapperLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setWrapperLoading(false);
        });
    } catch (error) {
      console.log(error);
      setWrapperLoading(false);
    }
  };

  function lightOrDark(color, type) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    if (color) {
      // Check the format of the color, HEX or RGB?
      if (color.match(/^rgb/)) {
        // If RGB --> store the red, green, blue values in separate variables
        color = color.match(
          /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
        );

        r = color[1];
        g = color[2];
        b = color[3];
      } else {
        // If hex --> Convert it to RGB: http://gist.github.com/983661
        color = +(
          "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
        );

        r = color >> 16;
        g = (color >> 8) & 255;
        b = color & 255;
      }

      // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
      hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

      // Using the HSP value, determine whether the color is light or dark
      // if hsp> 127.5 then it is dark
      if (hsp > 127.5) {
        if (type == "header") {
          setIsLightHeaderColor(true);
        } else if (type == "button") {
          setIsLightButtonColor(true);
        } else {
          setIsLightHoverColor(true);
        }
      } else {
        if (type == "header") {
          setIsLightHeaderColor(false);
        } else if (type == "button") {
          setIsLightButtonColor(false);
        } else {
          setIsLightHoverColor(false);
        }
      }
    }
  }

  const Wrapper = styled.div`
    & .whole__shapes .shapes__block .image:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block:hover .image {
      border: 1px solid ${varColorObj.hoverColor};
    }

    & .whole__shapes .shapes__block .image .selected {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .whole__shapes .shapes__block.selected .image {
      border: 1px solid ${varColorObj.linkColor};
    }

    & .img__block .imgselct__div:hover {
      border-bottom: 2px solid ${varColorObj.hoverColor};
    }

    & .img__block .imgselct__div.selected {
      border-bottom: 2px solid ${varColorObj.linkColor};
    }

    & .selection__block .selection__box:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background-color: ${varColorObj.hoverColor};
    }
    & .selection__block .selection__box.selected {
      border: 1px solid ${varColorObj.linkColor};
      background-color: ${varColorObj.linkColor};
    }

    & .ant-table-thead > tr > th {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      font-family: "LexendDeca-Semibold";
      height: 50px;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
      line-height: 14px;
    }

    & .ant-btn-primary {
      background: ${varColorObj.callToActionColor} !important;
      border-color: ${varColorObj.callToActionColor} !important;
    }
    & .ant-btn-primary:hover {
      background: ${varColorObj.hoverColor} !important;
      border-color: ${varColorObj.hoverColor} !important;
    }

    & .primary-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .primary-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn {
      background: ${varColorObj.callToActionColor} !important;
      border-radius: 5px !important;
      color: ${isLightButtonColor ? "#3b4452" : "#fff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }

    & .ant-btn:hover {
      background: ${varColorObj.hoverColor} !important;
      border-radius: 5px !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
      border: none;
      padding: 0px 10px;
      height: 35px;
    }
    & .ant-input-search-button {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }

    & .ant-input-search-button:hover {
      background: #fff !important;
      border: 1px solid #d9d9d9;
      border-radius: 0 !important;
      height: 32px;
      border-left: 0;
    }
    & .linkText {
      color: ${varColorObj.linkColor};
      text-decoration: none;
      cursor: pointer;
    }

    & .linkText:hover {
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover img {
      filter: invert(64%) sepia(29%) saturate(4028%) hue-rotate(164deg)
        brightness(93%) contrast(89%);
      color: ${varColorObj.hoverColor};
    }

    & .iframe__block .itemdetails__btns p:hover span {
      color: ${varColorObj.hoverColor};
    }

    & .ant-pagination-item-active {
      border: 1px solid ${varColorObj.linkColor} !important;
    }

    & .ant-pagination-item:hover {
      border: 1px solid ${varColorObj.hoverColor};
    }
    & .nav-tabs .nav-link.active {
      background: ${varColorObj.columnHeaderAccentColor} !important;
      color: ${isLightHeaderColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon.active {
      border: 1px solid ${varColorObj.callToActionColor};
      background: ${varColorObj.callToActionColor} !important;
      color: ${isLightButtonColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .grid_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .list_icon:hover {
      border: 1px solid ${varColorObj.hoverColor};
      background: ${varColorObj.hoverColor} !important;
      color: ${isLightHoverColor ? "#3b4452" : "#ffffff"} !important;
    }

    & .ant-input-search-button {
      color: rgba(0, 0, 0, 0.45) !important;
    }
    & .ant-input-search-button:hover {
      color: rgba(0, 0, 0, 0.45) !important;
    }
  `;

  /* Color Individually Ends */

  // useEffect(() => {
  //   if (previewcolor) {
  //     if (previewcolor.buttonColor) {
  //       setVarColorObj({
  //         ...varColorObj,
  //         callToActionColor: previewcolor.buttonColor.callToActionColor,
  //         hoverColor: previewcolor.buttonColor.hoverColor,
  //         columnHeaderAccentColor:
  //           previewcolor.buttonColor.columnHeaderAccentColor,
  //         linkColor: previewcolor.buttonColor.linkColor,
  //       });

  //       setToggle(previewcolor.toogle);
  //     }
  //   }
  // }, [previewcolor.toogle, toggle]);

  // CSS Variables for Color panel

  var primaryBtn = {
    backgroundColor: varColorObj.callToActionColor,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "#fff",
    border: "none",
    padding: "0px 10px",
    height: "35px",
  };

  var primaryBtnHover = {
    backgroundColor: varColorObj.hoverColor,
    borderBottomLeftRadius: "5px",
    borderBottomRightRadius: "5px",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    color: "#fff",
    border: "none",
    padding: "0px 10px",
    height: "35px",
  };

  const handleMouseEnter = (name) => {
    setIsHovering(true);
    setName(name);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
    setName("");
  };

  // useEffect(() => {
  //   setState({
  //     PriceMin: 0,
  //     PriceMax: 9999999,
  //     CaratMin: 0,
  //     CaratMax: 22,
  //     Shape: [],
  //     Cut: [],
  //     Color: [],
  //     Polish: [],
  //     Fluorescence: [],
  //     Symmetry: [],
  //     productChange: {},
  //   });
  //   setPriceValue([0, 9999999]);
  //   setCaratSlideValue([0, 22]);
  // }, [resetVal.resetMined]);

  useEffect(() => {
    if (paramdealerId) {
      debugger;
      if (paramdealerId) {
        if (resetVal) {
          handleGetMinedDiamondFilters(
            paramdealerId,
            pageNo,
            pageSize,
            resetVal.saveSearchMined
          );
          setDealerId(paramdealerId);
          handleGetstudBuilderOptiondetails(paramdealerId);
        }
        dispatch(handleHideCompletethisMounting());
        dispatch(handleEditViewHide());
      }
    }
  }, [resetVal.resetMined, resetVal.saveSearchMined]);
  // }, []); //  }, [resetVal.resetMined, resetVal.saveSearchMined]);

  const handleOnChange = (row, e) => {
    const temp = minedList.map((x) => {
      if (x.intDiamondId == row.intDiamondId) {
        return { ...x, checkbox: e == true ? true : false };
      } else return x;
    });

    let saveRow = temp.filter((obj) => obj.checkbox == true);
    setMinedList(temp);
    var CompareIds = saveRow.map((x) => x.intDiamondId);
    let compareData = {
      ids: CompareIds,
      pageName: "Mined",
    };
    dispatch(handleComaparePageIds(compareData));
  };

  const handleChooseFirstSetting = (details) => {
    let obj = details;

    dispatch(handleViewAddToStudPageDetails(obj));
  };

  const handleChooseSetting = (details) => {
    let obj = details;
    debugger;
    navigate(
      "/chooseYourSetting" +
        `?isSocialPage=True&DealerID=${paramdealerId}&dealerID=${obj.dealerID1}&paramDetailLinkURL=${obj.detailLinkURL}&diamondId=${obj.diamondID1}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&size=${obj.size1}&pairId=${obj.pairID}&totalPrice=${obj.totalPrice}&isTab=false&tabName=chooseyourmounting&diamondViewEdit=True`
    );

    dispatch(handleViewAddToStudPageDetails(obj));
    dispatch(handleViewCompletethisMounting());
    dispatch(handleHideEditChooseThisMounting());
    dispatch(handleEditViewShow());
    dispatch(handleCompleteEarningTwoDiamond(obj));

    dispatch(handleActiveTab("chooseYourSetting"));
  };

  const navigate = useNavigate();
  const handleCompleteEarningDiamond = (details) => {
    debugger;
    let obj = details;
    console.log(obj);
    dispatch(handleCompleteEarningTwoDiamond(obj));

    navigate(
      "/viewDiamondDetails" +
        `?isSocialPage=True&DealerID=${paramdealerId}&paramDetailLinkURL=${obj.detailLinkURL}&pageNo=1&isAllowed=True&paramCut=${obj.cut1}&diamondId=${obj.diamondID1}&isTab=false&tabName=selectYourDiamond`
    );
    // dispatch(handleActiveTab("completeYourRing"))
  };

  console.log(myOptionsData);

  const columns = [
    {
      title: "Shape",
      dataIndex: "cut1",
      width: 150,
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <div className="stud__img">
              <img
                style={{ width: "20px", height: "20px" }}
                src={row.imageFileName}
              />
              <span>{" " + row.cut1}</span>
            </div>

            <div className="stud__img">
              <img
                style={{ width: "20px", height: "20px" }}
                src={row.imageFileName}
              />
              <span>{" " + row.cut2}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "intDiamondId",
      className:
        myOptionsData.showDealerDiamondsSKU == false ? "display-none" : "",
      render: (item, row) => {
        return (
          <div>
            <span>{row.diamondID1}</span>
            <br></br>
            <span>{row.diamondID2}</span>
          </div>
        );
      },
    },
    {
      title: "Carats",
      dataIndex: "size1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.size1}</span>
            <br></br>
            <span>{row.size2}</span>
          </div>
        );
      },
    },
    {
      title: "Color",
      dataIndex: "color1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.color1}</span>
            <br></br>
            <span>{row.color2}</span>
          </div>
        );
      },
    },
    {
      title: "Clarity",
      dataIndex: "clarity1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.clarity1}</span>
            <br></br>
            <span>{row.clarity2}</span>
          </div>
        );
      },
    },
    {
      title: "Cut",
      dataIndex: "cutGrade1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.cutGrade1}</span>
            <br></br>
            <span>{row.cutGrade2}</span>
          </div>
        );
      },
    },
    {
      title: "Depth",
      dataIndex: "depth1",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.depth1}</span>
            <br></br>
            <span>{row.depth2}</span>
          </div>
        );
      },
    },
    {
      title: "Table",
      dataIndex: "tableMeasure1",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.tableMeasure1}</span>
            <br></br>
            <span>{row.tableMeasure2}</span>
          </div>
        );
      },
    },
    {
      title: "Polish",
      dataIndex: "polish1",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.polish1}</span>
            <br></br>
            <span>{row.polish2}</span>
          </div>
        );
      },
    },
    {
      title: "Symmetry",
      dataIndex: "symmetry1",
      className: "hideInMobile",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.symmetry1}</span>
            <br></br>
            <span>{row.symmetry2}</span>
          </div>
        );
      },
    },
    {
      title: "Measurement",
      className: "hideInMobile",
      dataIndex: "measurements1",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            <span>{row.measurements1}</span>
            <br></br>
            <span>{row.measurements2}</span>
          </div>
        );
      },
    },
    {
      title: "Cert.",
      dataIndex: "certificate1",
      className: "my-hover-pointer linkText",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return (
          <div>
            {row.certificateLink !== "" && (
              <span onClick={() => handleOpenCertificate(row.certificateLink)}>
                {row.certificate1}
              </span>
            )}

            <br></br>
            {row.certificateLink2 !== "" && (
              <span onClick={() => handleOpenCertificate(row.certificateLink2)}>
                {row.certificate2}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "totalPrice",
      sorter: (a, b) => {},
      showSorterTooltip: false,
      render: (item, row) => {
        return myOptionsData.showCallForPrice == true ? (
          <span>Call</span>
        ) : (
          <>
            {row.totalPrice == "0" ? (
              <>
                Call <span className="hideInMobile">For Price</span>
              </>
            ) : (
              <span>
                $
                {parseInt(row.totalPrice)
                  .toFixed()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </span>
            )}
          </>
          // <span>{"$" + row.totalPrice}</span>
        );
      },
    },
    {
      title: "View",
      dataIndex: "",
      width: 100,
      render: (index, row) => (
        <div>
          <div className="action__btns">
            <div className="image__block">
              <img
                src={Eye_icon}
                alt=""
                onClick={() => handleCompleteEarningDiamond(row)}
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const { Option } = Select;

  const handleProductChangeOpen = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = true;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleProductChangeClose = (intDiamondId) => {
    var newproductChange = productChange;
    newproductChange[intDiamondId] = false;
    setProductChange((prevState) => ({
      ...prevState,
      ...newproductChange,
    }));
  };

  const handleViewChange = (viewname) => {
    setViewItem(viewname);
  };

  const handleViewDetails = (details, type) => {
    let obj = details;
    obj["pageName"] = "Mined";
    obj["shapeFilters"] = filterVals.Shape;
    dispatch(handleViewDetailsPage(obj));

    if (type == "addSetting") {
      dispatch(handleActiveTab("chooseYourSetting"));
    }
    dispatch(handleAddDiamondViewEdit());
    // dispatch(handleSetDiamondDetails(details));
  };
  const handleHideShowFilter = () => {
    setHideShowFilter(!hideShowFilter);
  };

  const onKeyPressEvent1 = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!new RegExp("[0-9.]").test(keyValue)) event.preventDefault();
    return;
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const firstSliderPriceValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...priceValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(Value.replace(/,/g, ""))) > state.PriceMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (Value == "") {
        newArr[0] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[0] = Value.replace(/,/g, "");
      //   setPriceValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["PriceMin"] = Value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[0] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        newArr[0] == "" ? 0 : newArr[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    } else if (Number(num) == 1) {
      if (Value == "") {
        newArr[1] = 0;
        setPriceValue(newArr);
        var tempArray = filterVals;
        tempArray["PriceMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      } else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setPriceValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setPriceValue(newArr);
          } else {
            newArr[1] = Value;
            setPriceValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["PriceMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    }
  };

  const firstsetCaratSlideValue = (e, num) => {
    setIsFilter(true);
    let newArr = [...caratSlideValue];
    var StrValue = e.target.value && e.target.value.replace("$", "");
    var Value = StrValue.replace(/,/g, "");
    if (Number(parseFloat(e.target.value.replace(/,/g, ""))) > state.CaratMax) {
      e.preventDefault();
    } else if (Number(num) == 0) {
      if (e.target.value == "") {
        newArr[0] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMin"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[0] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMin"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[0] == 0 && Value.includes(".")) {
          newArr[0] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[0] == 0) {
            newArr[0] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[0] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMin"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }

      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        newArr[0] == "" ? 0 : newArr[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
      console.log(caratSlideValue);
    } else if (Number(num) == 1) {
      if (e.target.value == "") {
        newArr[1] = 0;
        setCaratSlideValue(newArr);
        var tempArray = filterVals;
        tempArray["CaratMax"] = 0;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      // else {
      //   newArr[1] = e.target.value.replace(/,/g, "");
      //   setCaratSlideValue(newArr);
      //   var tempArray = filterVals;
      //   tempArray["CaratMax"] = e.target.value.replace(/,/g, "");
      //   setFilterVals(tempArray);
      //   dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      // }
      else {
        if (newArr[1] == 0 && Value.includes(".")) {
          newArr[1] = Value;
          setCaratSlideValue(newArr);
        } else {
          if (newArr[1] == 0) {
            newArr[1] = parseFloat(Value);
            setCaratSlideValue(newArr);
          } else {
            newArr[1] = Value;
            setCaratSlideValue(newArr);
          }
        }

        var tempArray = filterVals;
        tempArray["CaratMax"] = Value;
        setFilterVals(tempArray);
        dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
      }
      handleGetMinedDiamondList(
        dealerId,
        pageNo,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        newArr[1] == "" ? 0 : newArr[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        filterVals.Certificates, // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence, //fluorescence
        true // isFilter
      );
    }
  };

  const onChangeMultiDrop = (e, name) => {
    let tempState = filterVals;
    if (e.includes("%")) {
      tempState.disabled[name] = true;
      tempState[name] = [];
      tempState[name].push("%");
    } else {
      tempState.disabled[name] = false;
      tempState[name] = e;
    }
    setFilterVals((prevState) => ({
      ...prevState,
      ...tempState,
    }));
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Intensity,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      tempState.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Intensity, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
    dispatch(handleSetSaveEveryTimeMinedTab(filterVals));
  };

  const handleLabGrownFilterChange = (val, name, type) => {
    setIsFilter(true);
    if (type == "filter") {
      let tempArray = filterVals[name];
      if (tempArray.includes(val)) {
        const index = tempArray.indexOf(val);
        tempArray.splice(index, 1);
      } else {
        tempArray.push(val);
      }
      setFilterVals({
        ...filterVals,
        [name]: tempArray,
      });
      if (name == "Shape") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          tempArray,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }

      if (name == "Cut") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "Color") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          tempArray, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "Clarity") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          tempArray, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "Polish") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          tempArray, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "Fluorescence") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          tempArray, //fluorescence
          true // isFilter
        );
      }
      if (name == "Symmetry") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          tempArray, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
    } else {
      setFilterVals({
        ...filterVals,
        [name]: val,
      });
      if (name == "DepthMin") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          val,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "Certificates") {
        let allFilters = [];

        if (val.includes("all")) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
          setSelectedCertificates(allFilters);
        } else if (val.length == state.Certificates.length) {
          allFilters = state.Certificates.map((cert) => {
            return cert.value;
          });
          allFilters.push("all");
          setSelectedCertificates(allFilters);
        } else {
          setSelectedCertificates(val);
        }

        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          filterVals.TableMax,
          allFilters.length > 0 ? allFilters : val, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }

      if (name == "DepthMax") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          val,
          filterVals.TableMin,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "TableMin") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          val,
          filterVals.TableMax,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
      if (name == "TableMax") {
        handleGetMinedDiamondList(
          dealerId,
          pageNo,
          pageSize,
          filterVals.Shape,
          priceValue[0],
          priceValue[1],
          caratSlideValue[0],
          caratSlideValue[1],
          state.Color,
          state.Clarity,
          state.Cut,
          state.Polish,
          state.Symmetry,
          state.Fluorescence,
          filterVals.DepthMin,
          filterVals.DepthMax,
          filterVals.TableMin,
          val,
          filterVals.Certificates, // Certificate
          searchSku, //Sku
          filterVals.Color, // color
          filterVals.Clarity, // clarity
          filterVals.Cut, // cut
          filterVals.Polish, // polish
          filterVals.Symmetry, //symmetry
          filterVals.Fluorescence, //fluorescence
          true // isFilter
        );
      }
    }
    dispatch(handleSetSaveEveryTimeMinedTab(filterVals));
  };

  const handleInputDiamondDataChange = (e, name) => {
    setDiamondFormData({
      ...diamondFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleInputDiamondDataReset = () => {
    handleInputDiamondDataRequest();
  };

  const handleInputDiamondDataRequest = () => {
    let arrShapes =
      filterVals.Shape.length > 0 ? filterVals.Shape.map((x) => x) : null;
    let arrCutGrades =
      state.Cut.length > 0 ? state.Cut.map((x) => Number(x.value)) : null;
    let arrColors =
      state.Color.length > 0 ? state.Color.map((x) => Number(x.value)) : null;
    let arrPolishs =
      state.Polish.length > 0 ? state.Polish.map((x) => Number(x.value)) : null;
    let arrClaritys =
      state.Clarity.length > 0
        ? state.Clarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      state.Symmetry.length > 0
        ? state.Symmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      state.Fluorescence.length > 0
        ? state.Fluorescence.map((x) => Number(x.value))
        : null;

    try {
      let inputData = {
        dealerID: dealerId.toString(),
        firstName: diamondFormData.firstName,
        lastName: diamondFormData.lastName,
        phoneNumber: diamondFormData.phoneNumber,
        emailAddress: diamondFormData.emailAddress,
        comment: diamondFormData.comments,
        intDealerID: dealerId.toString(),
        subject: "",
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: filterVals.PriceMin.toString(),
        priceMax: filterVals.PriceMax.toString(),
        caratMin: filterVals.CaratMin.toString(),
        caratMax: filterVals.CaratMax.toString(),
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        clarityMin:
          arrClaritys !== null ? Math.min(...arrClaritys).toString() : "",
        clarityMax:
          arrClaritys !== null ? Math.max(...arrClaritys).toString() : "",
        cutgradeMin:
          arrCutGrades !== null ? Math.min(...arrCutGrades).toString() : "",
        cutgradeMax:
          arrCutGrades !== null ? Math.max(...arrCutGrades).toString() : "",
        certificate: "", //Certificate
        depthMin: filterVals.DepthMin.toString(),
        depthMax: filterVals.DepthMax.toString(),
        tableMin: filterVals.TableMin.toString(),
        tableMax: filterVals.TableMax.toString(),
        polishList:
          filterVals.Polish.length > 0 ? filterVals.Polish.toString() : "",
        symmetryList:
          filterVals.Symmetry.length > 0 ? filterVals.Symmetry.toString() : "",
        fluorescenceList:
          filterVals.Fluorescence.length > 0
            ? filterVals.Fluorescence.toString()
            : "",
        cutGradeList:
          filterVals.Cut.length > 0 ? filterVals.Cut.toString() : "",
        colorList:
          filterVals.Color.length > 0 ? filterVals.Color.toString() : "",
        clarityList:
          filterVals.Clarity.length > 0 ? filterVals.Clarity.toString() : "",
      };
      setReqDiamondLoading(true);
      SelectYourDiamondService.SendMailToRetailerForNoResults(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message == "Success") {
            setReqDiamondLoading(false);
            setDiamondFormData({
              firstName: "",
              lastName: "",
              phoneNumber: "",
              emailAddress: "",
              comments: "",
            });
          } else {
            setReqDiamondLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setReqDiamondLoading(false);
        });
    } catch (error) {
      console.log(error);
      setReqDiamondLoading(false);
    }
  };
  const handleGetMinedDiamondFilters = (id, pageNo, pageSize, val) => {
    debugger;
    try {
      let inputData = {
        dealerLink: id.toString(),
      };
      setFilterLoading(true);
      setLoading(true);
      SelectYourDiamondService.GetAvailableStudBuilderFilters(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData =
            response.data.responseData.availableFilters_Response;
          var tempAvailableFilterData = state;
          var tempAvailableFilterValData = filterVals;
          if (message == "Success") {
            debugger;
            if (responseData) {
              debugger;
              // tempAvailableFilterData.PriceMin = Number(responseData.priceMin);
              // tempAvailableFilterData.PriceMax = Number(responseData.priceMax);
              // tempAvailableFilterData.CaratMin = Number(responseData.caratMin);
              // tempAvailableFilterData.CaratMax = Number(responseData.caratMax);
              setIsAdvance(responseData.advanced);
              var shapData = [];

              shapData = responseData.studBuilderSettingsShape.filter(
                (x) => x.id !== 0
              );
              tempAvailableFilterData.Shape = shapData;

              // tempAvailableFilterData.Shape =
              //   responseData.studBuilderSettingsShape;

              tempAvailableFilterData.Cut =
                responseData.ringBuildersettingsCuts;
              tempAvailableFilterData.Color =
                responseData.ringBuildersettingsColor;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              tempAvailableFilterData.Polish =
                responseData.ringBuildersettingsPolish;
              tempAvailableFilterData.Fluorescence =
                responseData.ringBuildersettingsFluorescence;
              tempAvailableFilterData.Symmetry =
                responseData.ringBuildersettingsSymmetry;
              tempAvailableFilterData.Certificates =
                responseData.ringBuildersettingsCertificates;
              tempAvailableFilterData.Clarity =
                responseData.ringBuildersettingsClarity;
              // tempAvailableFilterData.TableMin = responseData.tableMin;
              // tempAvailableFilterData.TableMax = responseData.tableMax;
              // tempAvailableFilterData.DepthMin = responseData.depthMin;
              // tempAvailableFilterData.DepthMax = responseData.depthMax;

              // tempAvailableFilterValData.PriceMin = Number(
              //   responseData.priceMin
              // );
              // tempAvailableFilterValData.PriceMax = Number(
              //   responseData.priceMax
              // );
              // tempAvailableFilterValData.CaratMin = Number(
              //   responseData.caratMin
              // );
              // tempAvailableFilterValData.CaratMax = Number(
              //   responseData.caratMax
              // );
              // tempAvailableFilterValData.TableMin = responseData.tableMin;
              // tempAvailableFilterValData.TableMax = responseData.tableMax;
              // tempAvailableFilterValData.DepthMin = responseData.depthMin;
              // tempAvailableFilterValData.DepthMax = responseData.depthMax;
              setCaratSlideValue([0, 22]);
              setPriceValue([0, 9999999]);
              setState({
                ...state,
                ...tempAvailableFilterData,
              });

              setFilterVals({
                ...filterVals,
                ...tempAvailableFilterValData,
              });
              // setPriceValue([responseData.priceMin, responseData.priceMax]);
              // setCaratSlideValue([
              //   responseData.caratMin,
              //   responseData.caratMax,
              // ]);
              var saveSearchObj = resetVal.minedDiamondFilter;

              if (val == true) {
                setFilterVals(saveSearchObj);
                setPriceValue([saveSearchObj.PriceMin, saveSearchObj.PriceMax]);
                setCaratSlideValue([
                  saveSearchObj.CaratMin,
                  saveSearchObj.CaratMax,
                ]);
              } else {
                setFilterVals({
                  ...filterVals,
                  ...tempAvailableFilterValData,
                });
                var tempObj = tempAvailableFilterValData;
                tempAvailableFilterValData.Shape = [];
                tempAvailableFilterValData.Cut = [];
                tempAvailableFilterValData.Color = [];
                tempAvailableFilterValData.Clarity = [];
                tempAvailableFilterValData.Polish = [];
                tempAvailableFilterValData.Fluorescence = [];
                tempAvailableFilterValData.Symmetry = [];
                tempAvailableFilterValData.Intensity = [];
                tempAvailableFilterValData.Certificates = [];
                tempAvailableFilterValData.PriceMin = 0;
                tempAvailableFilterValData.PriceMax = 9999999;
                tempAvailableFilterValData.CaratMin = 0;
                tempAvailableFilterValData.CaratMax = 22;
                tempAvailableFilterValData.TableMin = 0;
                tempAvailableFilterValData.TableMax = 100;
                tempAvailableFilterValData.DepthMin = 0;
                tempAvailableFilterValData.DepthMax = 100;
                // setPriceValue([filterPricemin, filterPricemax]);
                // setCaratSlideValue([filterCaratmin, filterCaratmax]);
                tempObj["disabled"] = {};
                setFilterVals({
                  ...filterVals,
                  ...tempObj,
                });
                dispatch(handleSetSaveFilterMinedTab(tempObj));
              }
              setFilterLoading(false);
              if (val == true) {
                if (val == true) {
                  handleGetMinedDiamondList(
                    id,
                    pageNo,
                    pageSize,
                    saveSearchObj.Shape, //shapes
                    saveSearchObj.PriceMin,
                    saveSearchObj.PriceMax,
                    saveSearchObj.CaratMin,
                    saveSearchObj.CaratMax,
                    responseData.sldColorMax,
                    responseData.sldClarityMax,
                    responseData.sldCutGradeMax,
                    responseData.sldPolishMax,
                    responseData.sldSymmetryMax,
                    responseData.sldFluorescenceMax,
                    saveSearchObj.DepthMin,
                    saveSearchObj.DepthMax,
                    saveSearchObj.TableMin,
                    saveSearchObj.TableMax,
                    saveSearchObj.Certificate, // Certificate
                    searchSku, //Sku
                    saveSearchObj.Color, // color
                    saveSearchObj.Clarity, // clarity
                    saveSearchObj.Cut, // cut
                    saveSearchObj.Polish, // polish
                    saveSearchObj.Symmetry, //symmetry
                    saveSearchObj.Fluorescence, //fluorescence
                    true
                  );
                }
              } else {
                handleGetMinedDiamondList(
                  id,
                  pageNo,
                  pageSize,
                  [], //shapes
                  responseData.priceMin,
                  responseData.priceMax,
                  responseData.caratMin,
                  responseData.caratMax,
                  responseData.colorMax,
                  responseData.colorMin,
                  responseData.sldCutGradeMax,
                  responseData.sldPolishMax,
                  responseData.sldSymmetryMax,
                  responseData.sldFluorescenceMax,
                  responseData.sldDepthMin,
                  responseData.sldDepthMax,
                  responseData.sldTableMin,
                  responseData.sldTableMax,
                  "", // Certificate
                  searchSku, //Sku
                  [], // color
                  [], // clarity
                  [], // cut
                  [], // polish
                  [], //symmetry
                  [], //fluorescence
                  false // isFilter
                );
              }
            } else {
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setFilterLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setFilterLoading(false);
        });
    } catch (error) {
      console.log(error);
      setFilterLoading(false);
    }
  };

  const handleGetMinedDiamondList = (
    id,
    PageNo,
    PageSize,
    shapes,
    priceMin,
    priceMax,
    caratmin,
    caratmax,
    allColor,
    allClarity,
    allCut,
    allPolish,
    allSymmetry,
    allFluorescence,
    depthmin,
    depthmax,
    tablemin,
    tablemax,
    Certificate, // Certificate
    Sku, //Sku
    colorVal, // color
    clarityVal, // clarity
    cutVal, // cut
    polishVal, // polish
    symmetryVal, //symmetry
    fluorescenceVal, //fluorescence
    filter, // isFilter
    column,
    type
  ) => {
    let arrShapes = shapes.length > 0 ? shapes.map((x) => x) : null;
    let arrCutGrades =
      allCut && allCut.length > 0 ? allCut.map((x) => Number(x.value)) : null;
    let arrColors =
      allColor && allColor.length > 0
        ? allColor.map((x) => Number(x.value))
        : null;
    let arrPolishs =
      allPolish && allPolish.length > 0
        ? allPolish.map((x) => Number(x.value))
        : null;
    let arrClaritys =
      allClarity && allClarity.length > 0
        ? allClarity.map((x) => Number(x.value))
        : null;
    let arrSymmetrys =
      allSymmetry && allSymmetry.length > 0
        ? allSymmetry.map((x) => Number(x.value))
        : null;
    let arrFluorescences =
      allFluorescence && allFluorescence.length > 0
        ? allFluorescence.map((x) => Number(x.value))
        : null;

    try {
      // let inputData = {
      //   dealerID: "3075",
      //   shapeList: "Round,Princess",
      //   priceMin: "0",
      //   priceMax: "129000",
      //   caratMin: "0.2",
      //   caratMax: "20",
      //   colorMin: "65",
      //   colorMax: "75",
      //   clarityMin: "1",
      //   clarityMax: "10",
      //   cutgradeMin: "1",
      //   cutgradeMax: "4",
      //   symmetryMin: "1",
      //   symmetryMax: "4",
      //   polishMin: "1",
      //   polishMax: "4",
      //   fluorescenceMin: "1",
      //   fluorescenceMax: "5",
      //   certificate: "%",
      //   skuNo: "",
      //   pairCode: "1",
      //   pageSize: "7",
      //   currentPage: "1",
      //   orderBy: "TotalPrice",
      //   orderType: "Asc",
      //   detailsLink: "StudDiamond_Detail.aspx",
      //   did: "",
      //   depthMin: "0",
      //   depthMax: "6390",
      //   tableMin: "0",
      //   tableMax: "98",
      //   caratPriceMin: "0",
      //   caratPriceMax: "0",
      //   cutGradeList: "2,3",
      //   colorList: "0",
      //   clarityList: "",
      //   polishList: "",
      //   symmetryList: "",
      //   fluorescenceList: "",
      //   extraQStr: "&SID=&Shape=&CTW=&Size=",
      //   themeName: "WhiteTheme",
      //   countryTaxRate: "77",
      //   countryCode: ""
      // };

      let inputData = {
        dealerID: id.toString(),
        shapeList: arrShapes !== null ? arrShapes.toString() : "",
        priceMin: priceMin ? priceMin.toString() : "0",
        priceMax: priceMax ? priceMax.toString() : "9999999",
        caratMin: caratmin.toString(),
        caratMax: caratmax.toString(),
        colorMax: arrColors !== null ? Math.max(...arrColors).toString() : "",
        colorMin: arrColors !== null ? Math.min(...arrColors).toString() : "",

        clarityMin:
          arrClaritys !== null && arrClaritys.length
            ? Math.min(...arrClaritys).toString()
            : "",
        clarityMax:
          arrClaritys !== null && arrClaritys.length
            ? Math.max(...arrClaritys).toString()
            : "",
        cutGradeMin:
          arrCutGrades !== null && arrCutGrades
            ? Math.min(...arrCutGrades).toString()
            : "",
        cutGradeMax:
          arrCutGrades !== null && arrCutGrades
            ? Math.max(...arrCutGrades).toString()
            : "",
        symmetryMin:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.min(...arrSymmetrys).toString()
            : "",
        symmetryMax:
          arrSymmetrys !== null && arrSymmetrys
            ? Math.max(...arrSymmetrys).toString()
            : "",
        polishMin:
          arrPolishs !== null && arrPolishs
            ? Math.min(...arrPolishs).toString()
            : "",
        polishMax:
          arrPolishs !== null && arrPolishs
            ? Math.max(...arrPolishs).toString()
            : "",

        fluorescenceMin:
          arrFluorescences !== null && arrFluorescences
            ? Math.min(...arrFluorescences).toString()
            : "",
        fluorescenceMax:
          arrFluorescences !== null && arrFluorescences
            ? Math.max(...arrFluorescences).toString()
            : "",

        certificate:
          Certificate && Certificate.length > 0 ? Certificate.toString() : "",
        skuNo: Sku.toString(),
        pairCode: "",

        pageSize: PageSize.toString(),
        currentPage: PageNo.toString(),
        orderBy:
          column == "cut1"
            ? "Cut1"
            : column == "size1"
            ? "Size1"
            : column == "color1"
            ? "Color1"
            : column == "clarity1"
            ? "Clarity1"
            : column == "cutGrade1"
            ? "CutGrade1"
            : column == "depth1"
            ? "Depth1"
            : column == "tableMeasure1"
            ? "TableMeasure1"
            : // : column == "polish1"
            // ? "Polish"
            // : column == "txtSymmetry"
            // ? "Symmetry"
            // : column == "txtMeasurements"
            // ? "Measurements"
            column == "certificate1"
            ? "Certificate1"
            : column == "totalPrice"
            ? "TotalPrice"
            : "TotalPrice",
        orderType: type ? type : "ASC",

        detailsLink: "StudDiamond_Detail.aspx",
        did: "",
        depthMin: depthmin ? depthmin.toString() : "",
        depthMax: depthmax ? depthmax.toString() : "",
        tableMin: tablemin ? tablemin.toString() : "",
        tableMax: tablemax ? tablemax.toString() : "",
        caratPriceMin: "",
        caratPriceMax: "",

        cutGradeList: cutVal && cutVal.length > 0 ? cutVal.toString() : "",
        colorList: colorVal && colorVal.length > 0 ? colorVal.toString() : "",
        clarityList:
          clarityVal && clarityVal.length > 0 ? clarityVal.toString() : "",

        polishList:
          polishVal && polishVal.length > 0 ? polishVal.toString() : "",

        symmetryList:
          symmetryVal && symmetryVal.length > 0 ? symmetryVal.toString() : "",

        fluorescenceList:
          fluorescenceVal && fluorescenceVal.length > 0
            ? fluorescenceVal.toString()
            : "",

        themeName: "WhiteTheme",
        countryTaxRate: "",
        countryCode: "",
      };
      setLoading(true);
      SelectYourDiamondService.LoadDiamonds(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          var FirstRow = response.data.responseData[0];
          if (message == "Success") {
            if (responseData.length > 0) {
              setMinedList(responseData);
              setTotalRowCount(responseData[0].intTotalRecords);
              setRowDetails(FirstRow);
              handleChooseFirstSetting(FirstRow);
              console.log(rowDetails.length);
              setLoading(false);
            } else {
              setMinedList([]);
              setTotalRowCount(0);
              setLoading(false);
              setRowDetails([]);
              console.log(rowDetails.length);
            }
          } else {
            setMinedList([]);
            setTotalRowCount(0);
            setLoading(false);
            setRowDetails([]);
            console.log(rowDetails.length);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  function onChange(page, pageSize) {
    if (page !== pageNo) {
      setPageNo(page);
      handleGetMinedDiamondList(
        dealerId,
        page,
        pageSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        "", // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
    }
  }
  function onShowSizeChange(current, pageNewSize) {
    if (pageNewSize !== pageSize) {
      setPageSize(pageNewSize);
      handleGetMinedDiamondList(
        dealerId,
        current,
        pageNewSize,
        filterVals.Shape,
        priceValue[0],
        priceValue[1],
        caratSlideValue[0],
        caratSlideValue[1],
        state.Color,
        state.Clarity,
        state.Cut,
        state.Polish,
        state.Symmetry,
        state.Fluorescence,
        filterVals.DepthMin,
        filterVals.DepthMax,
        filterVals.TableMin,
        filterVals.TableMax,
        "", // Certificate
        searchSku, //Sku
        filterVals.Color, // color
        filterVals.Clarity, // clarity
        filterVals.Cut, // cut
        filterVals.Polish, // polish
        filterVals.Symmetry, //symmetry
        filterVals.Fluorescence //fluorescence
      );
    }
  }

  const handleSetRowDetils = (e, record) => {
    console.log(e, record);
    setRowDetails(record);
  };

  const handleOpenVideoImageModal = (row, isVideo) => {
    setIsVideo(isVideo);
    setSelectedRowDetails(row);
    setVideoImageModal(true);
  };
  const handleCloseVideoImageModal = () => {
    setVideoImageModal(false);
  };

  const handleSearchDiamond = (value) => {
    setSearchSku(value);
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      "", // Certificate
      value, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence //fluorescence
    );
  };

  const handleFilterChangeCaratVal = (e) => {
    setCaratSlideValue(e);
  };

  const handleFilterChangePriceVal = (e) => {
    setPriceValue(e);
  };

  // Sorting

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    var filter = [];
    var obj = {};

    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
      setSortColumnName(column);
    } else {
      type = "ASC";
      column = "dealerCompany";
      setSortColumnName(column);
      setSortType(type);
    }
    console.log(pagination);
    // setPageNo(pagination.current);
    // setPageSize(pagination.pageSize);

    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      "", // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      filter,
      column,
      type
    );

    // document.getElementsByClassName("content__area")[0].scroll(0, 0);
  };

  // Certificate Link

  const handleOpenCertificate = (link) => {
    window.open(
      link,
      "",
      "width=500,height=500, top=' + top + ', left=' + left"
    );
  };

  //

  const handleAfterChange = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      priceValue[0],
      priceValue[1],
      e[0],
      e[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true //isFilter
    );
    var tempArray = filterVals;
    tempArray["CaratMin"] = e[0];
    tempArray["CaratMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  const handleAfterChangePrice = (e) => {
    handleGetMinedDiamondList(
      dealerId,
      pageNo,
      pageSize,
      filterVals.Shape,
      e[0],
      e[1],
      caratSlideValue[0],
      caratSlideValue[1],
      state.Color,
      state.Clarity,
      state.Cut,
      state.Polish,
      state.Symmetry,
      state.Fluorescence,
      filterVals.DepthMin,
      filterVals.DepthMax,
      filterVals.TableMin,
      filterVals.TableMax,
      filterVals.Certificates, // Certificate
      searchSku, //Sku
      filterVals.Color, // color
      filterVals.Clarity, // clarity
      filterVals.Cut, // cut
      filterVals.Polish, // polish
      filterVals.Symmetry, //symmetry
      filterVals.Fluorescence, //fluorescence
      true //isFilter
    );
    var tempArray = filterVals;
    tempArray["PriceMin"] = e[0];
    tempArray["PriceMax"] = e[1];
    setFilterVals(tempArray);
    dispatch(handleSetSaveEveryTimeMinedTab(tempArray));
  };

  return (
    <React.Fragment>
      <div className="allprod_section jwlsrch__section app__preview choose__setting">
        <div className="tabs__content">
          <div className="diamond__details">
            <div className="row border__block">
              <div className="col-md-9">
                <div className="row">
                  <Spin spinning={filterloading}>
                    <div className="form__fields filter_inpsection border-0 p-0 mt-3 mb-3 mh-auto">
                      <div className="col-md-12">
                        <div className="row">
                          <Wrapper>
                            <div className="col-lg-3 col-md-6">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Shape
                                  {/* <Tooltip
                                    placement="right"
                                    title="A diamond’s shape is not the same as a diamond’s cut. The shape refers to the general outline of the stone, and not its light refractive qualities. Look for a shape that best suits the ring setting you have chosen, as well as the recipient’s preference and personality."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                            </div>

                            <div className="whole__shapes col-lg-12">
                              {state.Shape &&
                                state.Shape.map((item, i) => {
                                  return (
                                    <Fragment>
                                      <div
                                        className={
                                          filterVals.Shape &&
                                          filterVals.Shape.includes(item.value)
                                            ? "shapes__block selected"
                                            : "shapes__block"
                                        }
                                        onClick={() =>
                                          handleLabGrownFilterChange(
                                            item.value,
                                            "Shape",
                                            "filter"
                                          )
                                        }
                                      >
                                        <div className="image">
                                          <img src={item.shapeImage} />
                                        </div>
                                        <p>{item.name}</p>
                                      </div>
                                    </Fragment>
                                  );
                                })}
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Cut
                                  {/* <Tooltip
                                    placement="right"
                                    title="Not to be confused with shape, a diamond’s cut rating tells you how well its proportions interact with light. By evaluating the angles and proportions of the diamond, the cut grade is designed to tell you how sparkly and brilliant your stone is. Cut grading is usually not available for fancy shapes (any shape that is not round), because the mathematical formula that determines light return becomes less reliable when different length to width ratios are factored in."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Cut &&
                                  state.Cut.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Cut &&
                                              filterVals.Cut.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Cut",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Color
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Color &&
                                  state.Color.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Color &&
                                              filterVals.Color.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Color",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input__block mb-1">
                                <label>
                                  {" "}
                                  Clarity
                                  {/* <Tooltip
                                    placement="right"
                                    title="The color scale measures the degree of colorlessness in a diamond. D is the highest and most colorless grade, but also the most expensive. To get the most value for your budget, look for an eye colorless stone. For most diamonds, this is in the F-H range."
                                  >
                                    <img src={UnionIcon} alt="" />
                                  </Tooltip> */}
                                </label>
                              </div>
                              <div className="selection__block row">
                                {state.Clarity &&
                                  state.Clarity.map((item, i) => {
                                    return (
                                      <Fragment>
                                        <div className="col">
                                          <div
                                            className={
                                              filterVals.Clarity &&
                                              filterVals.Clarity.includes(
                                                item.value
                                              )
                                                ? "selection__box selected"
                                                : "selection__box"
                                            }
                                            onClick={() =>
                                              handleLabGrownFilterChange(
                                                item.value,
                                                "Clarity",
                                                "filter"
                                              )
                                            }
                                          ></div>
                                          <p>{item.name}</p>
                                        </div>
                                      </Fragment>
                                    );
                                  })}
                              </div>
                            </div>
                          </Wrapper>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Carats"} </label>
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={caratSlideValue}
                                  min={state.CaratMin}
                                  max={state.CaratMax}
                                  step={0.01}
                                  onChange={(e) => {
                                    handleFilterChangeCaratVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChange(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[0].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 0);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={caratSlideValue[1].toLocaleString(
                                        "en-US"
                                      )}
                                      onChange={(e) => {
                                        firstsetCaratSlideValue(e, 1);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="sliderrange__maindiv">
                              <label> {"Price Range"} </label>
                              <div className="col-md-12 mt-3">
                                <Slider
                                  range
                                  value={priceValue}
                                  min={state.PriceMin}
                                  max={state.PriceMax}
                                  onChange={(e) => {
                                    handleFilterChangePriceVal(e);
                                  }}
                                  onAfterChange={(e) => {
                                    handleAfterChangePrice(e);
                                  }}
                                />
                                <div className="rangeSlider__values">
                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={priceValue[0]
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 0);
                                      }}
                                    />
                                  </div>

                                  <div className="input__block">
                                    <input
                                      type="text"
                                      value={
                                        "$" +
                                        priceValue[1]
                                          .toString()
                                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                      }
                                      onChange={(e) => {
                                        firstSliderPriceValue(e, 1);
                                        // handleSliderCaratValue(e);
                                        // setCaratSlideValue(e);
                                      }}
                                      onKeyPress={onKeyPressEvent1}
                                    />
                                    {/* <span className="dollar__symbol">$</span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Wrapper>
                            {isAdvance && (
                              <div className="col-md-12 mt-2">
                                <div className="input__block d-flex align-item-center">
                                  <button
                                    className="primary-btn mr-1"
                                    style={{
                                      width: "25px",
                                      height: "25px",
                                      padding: "0px",
                                    }}
                                    onClick={() =>
                                      setShowAdvanced(!showAdvanced)
                                    }
                                  >
                                    +
                                  </button>
                                  <label>Advanced</label>
                                </div>
                              </div>
                            )}

                            {showAdvanced == true ? (
                              <div>
                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Polish
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Polish &&
                                      state.Polish.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Polish &&
                                                  filterVals.Polish.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Polish",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Fluorescence
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Fluorescence &&
                                      state.Fluorescence.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Fluorescence &&
                                                  filterVals.Fluorescence.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Fluorescence",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="input__block mb-1">
                                    <label>
                                      {" "}
                                      Symmetry
                                      {/* <Tooltip placement="right" title="">
                                        <img src={UnionIcon} alt="" />
                                      </Tooltip> */}
                                    </label>
                                  </div>
                                  <div className="selection__block row">
                                    {state.Symmetry &&
                                      state.Symmetry.map((item, i) => {
                                        return (
                                          <Fragment>
                                            <div className="col">
                                              <div
                                                className={
                                                  filterVals.Symmetry &&
                                                  filterVals.Symmetry.includes(
                                                    item.value
                                                  )
                                                    ? "selection__box selected"
                                                    : "selection__box"
                                                }
                                                onClick={() =>
                                                  handleLabGrownFilterChange(
                                                    item.value,
                                                    "Symmetry",
                                                    "filter"
                                                  )
                                                }
                                              ></div>
                                              <p>{item.name}</p>
                                            </div>
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="input__block mb-1">
                                      <label>Depth</label>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMin",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6 mobile__topspace">
                                          <input
                                            type="number"
                                            value={filterVals.DepthMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "DepthMax",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Table</label>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <input
                                            type="number"
                                            value={filterVals.TableMin}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMin",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="col-md-6 mobile__topspace">
                                          <input
                                            type="number"
                                            value={filterVals.TableMax}
                                            onChange={(e) =>
                                              handleLabGrownFilterChange(
                                                e.target.value,
                                                "TableMax",
                                                "input"
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="input__block">
                                      <label>Certificates</label>
                                      {/* <Select
                                      className="border__grey"
                                      showSearch
                                      placeholder="Certificates"
                                      optionFilterProp="children"
                                      onChange={(e) =>
                                        handleLabGrownFilterChange(
                                          e,
                                          "Certificates",
                                          "input"
                                        )
                                      }
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      mode="multiple"
                                      showArrow
                                      value={
                                        selectedCertificates.includes("all")
                                          ? "all"
                                          : selectedCertificates
                                      }
                                    >
                                      <Option value="all">
                                        Show All Certificates
                                      </Option>
                                      {state.Certificates.map((cert) => {
                                        return (
                                          <Option
                                            disabled={
                                              selectedCertificates
                                                ? selectedCertificates.includes(
                                                    "all"
                                                  )
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            value={cert.value}
                                          >
                                            {cert.name}
                                          </Option>
                                        );
                                      })}
                                    </Select> */}
                                      <Select
                                        className="border__grey"
                                        placeholder="All Certificates"
                                        optionFilterProp="children"
                                        onChange={(e) =>
                                          onChangeMultiDrop(e, "Certificates")
                                        }
                                        showSearch
                                        // mode="multiple"
                                        showArrow
                                        value={filterVals.Certificates}
                                      >
                                        <Option value="all">
                                          All Certificates
                                        </Option>
                                        {state.Certificates.map((item) => {
                                          return (
                                            <Option
                                              value={item.value}
                                              disabled={
                                                item.certificateValue == "%"
                                                  ? false
                                                  : filterVals.disabled
                                                      .Certificates
                                              }
                                            >
                                              {item.name}
                                            </Option>
                                          );
                                        })}
                                      </Select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                          </Wrapper>
                        </div>
                      </div>
                    </div>
                  </Spin>
                </div>
              </div>
              <div className="col-md-3">
                <div className="diamondimage__block">
                  <Spin spinning={loading}>
                    <h3 className="subheading">DIAMOND PREVIEW</h3>
                    {rowDetails.length == undefined ? (
                      <>
                        <img
                          src={
                            rowDetails.bigImageFileName
                              ? rowDetails.bigImageFileName
                              : roundDiamond
                          }
                        />
                        <p>Stock Number: {rowDetails.diamondID1}</p>
                        <img
                          src={
                            rowDetails.bigImageFileName
                              ? rowDetails.bigImageFileName
                              : roundDiamond
                          }
                        />
                        <p>Stock Number: {rowDetails.diamondID2}</p>
                        <h4>
                          {myOptionsData.showCallForPrice == true ? (
                            "Call"
                          ) : (
                            // <>${rowDetails.totalPrice}</>
                            <>
                              {rowDetails.totalPrice == "0" ? (
                                "Call For Price"
                              ) : (
                                <span>
                                  $
                                  {parseInt(rowDetails.totalPrice)
                                    .toFixed()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </span>
                              )}
                            </>
                          )}
                        </h4>
                        <div className="mb-3">
                          <button
                            className="primary-btn"
                            onClick={() => handleChooseSetting(rowDetails)}
                          >
                            Add To Stud
                          </button>
                        </div>

                        <div className="mb-3">
                          {/* <Link to={"/chooseSettingItemDetails"}> */}
                          <button
                            onClick={() =>
                              handleCompleteEarningDiamond(rowDetails)
                            }
                            className="primary-btn"
                          >
                            View Details
                          </button>
                          {/* </Link> */}
                        </div>
                      </>
                    ) : (
                      <>
                        <img src={NoPreview} />
                        <p>No Results Found </p>

                        <p>Use The Form Below</p>
                      </>
                    )}
                  </Spin>
                </div>
              </div>
            </div>
          </div>
          <Wrapper>
            {minedList.length > 0 ? (
              <div className="col-md-12">
                <div className="filter_section p-0">
                  <div className="col-md-12 mt-3">
                    <div className="filt_optndiv">
                      <div className="gview__div">
                        <span className="font__bold mr-2 similar__text">
                          {TotalRowCount} Similar Diamonds
                        </span>
                        {/* <span
                          className={
                            viewItem === "grid"
                              ? "fa fa-th grid_icon active"
                              : "fa fa-th grid_icon"
                          }
                          onClick={() => handleViewChange("grid")}
                        ></span> */}
                        {/* <span
                          className={
                            viewItem === "list"
                              ? "fa fa-th-list list_icon active"
                              : "fa fa-th-list list_icon"
                          }
                          onClick={() => handleViewChange("list")}
                        ></span>

                        <span className="font__bold ml-2">
                          Compare Items (
                          {minedList.filter(obj => obj.checkbox == true).length})
                        </span> */}
                      </div>
                      {/* <div className="drpdwn__div">
                      <span className="mr-2 font__bold">Price </span>
                      <Select className="select_inpt" placeholder="Low - High">
                        <option>Low - High</option>
                        <option>High - Low</option>
                      </Select>
                    </div> */}
                      {myOptionsData.showOnlyDiamondSearch == true && (
                        <div>
                          <Search
                            placeholder="Search Diamond Stock"
                            onSearch={(e) => handleSearchDiamond(e)}
                            allowClear
                            style={{ width: 250 }}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  {viewItem === "grid" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12">
                        <div className="prod__section">
                          <div className="row">
                            {minedList.length > 0 ? (
                              minedList.map((item, i) => {
                                return (
                                  <div className="col-lg-3 col-md-6 mb-3">
                                    {/* <Link
                                  to={"/productDetails"}
                                  state={{ productData: item }}
                                > */}
                                    <div className="prod__maindiv">
                                      <div className="display-block chkbx__div">
                                        <Checkbox
                                          name="checkbox"
                                          checkedChildren="Yes"
                                          unCheckedChildren="No"
                                          checked={
                                            item.checkbox == true ? true : false
                                          }
                                          onChange={(e) =>
                                            handleOnChange(
                                              item,
                                              e.target.checked,
                                              "checkbox"
                                            )
                                          }
                                        />
                                      </div>
                                      <div className="prodimg__div">
                                        {/* <img src={Rectangle} alt="" /> */}
                                        <img src={item.imageFileName} alt="" />
                                      </div>
                                      <div className="details__div">
                                        <span className="style__txt">
                                          {"Style No"}: {item.intDiamondId}
                                        </span>
                                        <span className="price__txt">
                                          $
                                          {Number(
                                            item.realPrice
                                          ).toLocaleString("en-US")}
                                        </span>
                                      </div>
                                      <div className="display-block editdel__div">
                                        <div
                                          onClick={() => {
                                            handleOpenVideoImageModal(
                                              item,
                                              true
                                            );
                                          }}
                                        >
                                          <img src={Video_icon} alt="" />
                                        </div>
                                      </div>
                                      {!productChange[item.intDiamondId] ? (
                                        <div
                                          className="info__div"
                                          onClick={() =>
                                            handleProductChangeOpen(
                                              item.intDiamondId
                                            )
                                          }
                                        >
                                          <img src={Info_icon} alt="" />
                                        </div>
                                      ) : (
                                        <div className="descr__div">
                                          <div className="overlay__block">
                                            <p>
                                              <span> {"Style No"} </span>
                                              <span>:</span>
                                              <span>{item.intDiamondId}</span>
                                            </p>
                                            <p>
                                              <span> {"GFinventory No"} </span>
                                              <span>:</span>
                                              <span>#{item.gfInventoryID}</span>
                                            </p>
                                            <p>
                                              <span> {"Product Name"} </span>
                                              <span>:</span>
                                              <span>{item.productName}</span>
                                            </p>
                                            <p>
                                              <span> {"Category"} </span>
                                              <span>:</span>
                                              <span> {item.categoryName}</span>
                                            </p>
                                            <p>
                                              <span> {"Collection"} </span>
                                              <span>:</span>
                                              <span>{item.collectionName}</span>
                                            </p>
                                            <p>
                                              <span> {"Vendor Name"} </span>
                                              <span>:</span>
                                              <span>{item.vendorName}</span>
                                            </p>
                                            <p>
                                              <span> {"Wholesale Price"} </span>
                                              <span>:</span>
                                              <span>
                                                ${item.wholesaleprice}
                                              </span>
                                            </p>
                                            <p>
                                              <span> {"Retail Price"} </span>
                                              <span>:</span>
                                              <span>${item.retailPrice}</span>
                                            </p>
                                          </div>
                                          <div
                                            className="close__div"
                                            onClick={() =>
                                              handleProductChangeClose(
                                                item.intDiamondId
                                              )
                                            }
                                          >
                                            <img src={Close} alt="" />
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {/* </Link> */}
                                  </div>
                                );
                              })
                            ) : (
                              <span className="mr-2">
                                No items found{" "}
                                <span
                                  className="linkText"
                                  onClick={() => setHideShowFilter(true)}
                                >
                                  reset
                                </span>{" "}
                                your filters
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                  {viewItem === "list" && (
                    <Spin spinning={loading}>
                      <div className="col-md-12 mt-4">
                        <div className="lviewprod__tbl">
                          <Table
                            columns={columns}
                            onChange={handleTableASCDES}
                            onRow={(record, recordIndex) => ({
                              onClick: (event) => {
                                handleSetRowDetils(event, record);
                              },
                            })}
                            rowClassName={(record) =>
                              record.intDiamondId == rowDetails.intDiamondId
                                ? "row-active"
                                : ""
                            }
                            dataSource={minedList}
                            scroll={{ x: 600 }}
                            pagination={false}
                          />
                        </div>
                        <div className="table__bottom__btn single__item">
                          <div>
                            <Pagination
                              current={pageNo}
                              pageSize={pageSize}
                              total={TotalRowCount}
                              onChange={onChange}
                              onShowSizeChange={onShowSizeChange}
                              showSizeChanger="true"
                            />
                          </div>
                        </div>
                      </div>
                    </Spin>
                  )}
                </div>
              </div>
            ) : (
              // (
              //   <div class="col-md-12">
              //     <div class="table__block filter_section p-0 mt-3">
              //       <h5 className="subheading">
              //         {" "}
              //         No Diamonds Listed In That Range{" "}
              //       </h5>
              //       <p>
              //         {" "}
              //         Please enter your contact information. One of our diamond
              //         specialists will contact you and assist you with your needs.{" "}
              //       </p>
              //       <div class="row p-0 border-0 form__fields mh-auto">
              //         <div class="col-md-6">
              //           <div class="input__block">
              //             <label> First Name </label>
              //             <input
              //               type="text"
              //               placeholder="Enter First Name"
              //               name="firstName"
              //               value={diamondFormData.firstName}
              //               onChange={handleInputDiamondDataChange}
              //             />
              //           </div>
              //         </div>
              //         <div class="col-md-6">
              //           <div class="input__block">
              //             <label> Last Name </label>
              //             <input
              //               type="text"
              //               placeholder="Enter Last Name"
              //               name="lastName"
              //               value={diamondFormData.lastName}
              //               onChange={handleInputDiamondDataChange}
              //             />
              //           </div>
              //         </div>
              //         <div class="col-md-6">
              //           <div class="input__block">
              //             <label> Phone Number </label>
              //             <input
              //               type="text"
              //               placeholder="Enter Phone Number"
              //               name="phoneNumber"
              //               value={diamondFormData.phoneNumber}
              //               onChange={handleInputDiamondDataChange}
              //             />
              //           </div>
              //         </div>
              //         <div class="col-md-6">
              //           <div class="input__block">
              //             <label> Email Address </label>
              //             <input
              //               type="text"
              //               placeholder="Enter Email Address"
              //               name="emailAddress"
              //               value={diamondFormData.emailAddress}
              //               onChange={handleInputDiamondDataChange}
              //             />
              //           </div>
              //         </div>
              //         <div class="col-md-12">
              //           <div class="input__block">
              //             <label> Comments </label>
              //             <textarea
              //               name="comments"
              //               value={diamondFormData.comments}
              //               onChange={handleInputDiamondDataChange}
              //             ></textarea>
              //           </div>
              //         </div>
              //         <div class="col-md-12">
              //           <div class="save__form">
              //             <Button
              //               loading={reqDiamondloading}
              //               className="mr-1"
              //               type="primary"
              //               onClick={handleInputDiamondDataRequest}
              //             >
              //               {" "}
              //               Request{" "}
              //             </Button>
              //             <Button
              //               type="primary"
              //               class="primary-btn"
              //               onClick={handleInputDiamondDataReset}
              //             >
              //               {" "}
              //               Reset{" "}
              //             </Button>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // )}
              <Spin spinning={loading}>
                <h5 className="subheading mt-3">
                  {" "}
                  No Diamonds Listed In That Range{" "}
                </h5>
              </Spin>
            )}
          </Wrapper>
        </div>
      </div>

      <Modal
        className="modalconsupld__section"
        style={{ top: 20 }}
        title={"Video"}
        width={500}
        visible={videoImageModal}
        onCancel={() => handleCloseVideoImageModal()}
        footer={[
          <Button onClick={() => handleCloseVideoImageModal()}>
            {"Cancel"}
          </Button>,
        ]}
      >
        <div className="col-lg-12">
          <div className="modal__body">
            <div className="col-lg-12">
              <div className="form__fields border-0 p-0">
                <div className="row">
                  {isVideo ? (
                    selectedRowDetails.videoFileName ? (
                      <iframe
                        width="100%"
                        height="450"
                        title="diamond-video"
                        className="VideoIframe"
                        src={selectedRowDetails.videoFileName}
                      ></iframe>
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  ) : selectedRowDetails.imageFileName ? (
                    <img
                      src={selectedRowDetails.imageFileName}
                      alt="img-diamond"
                    />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default MinedDiamond;
