import {
  VIEW_DETAILS_PAGE,
  HIDE_DETAILS_PAGE,
  VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE,
  VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE_Hide,
  VIEW_EDIT_SHOW,
  VIEW_EDIT_HIDE
} from "./types";

export const handleViewDetailsPage = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_DETAILS_PAGE,
      payload: data
    });
  } catch (error) {}
};

export const handleHideDetailsPage = data => async dispatch => {
  try {
    dispatch({
      type: HIDE_DETAILS_PAGE
    });
  } catch (error) {}
};

export const handleCompleteEarningTwoDiamond = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE,
      payload: data
    });
  } catch (error) {}
};

export const handleHideCompleteEarningTwoDiamond = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_Complete_Earning_TwoDiamond_DETAILS_PAGE_Hide
    });
  } catch (error) {}
};

export const handleEditViewShow = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_EDIT_SHOW
    });
  } catch (error) {}
};

export const handleEditViewHide = data => async dispatch => {
  try {
    dispatch({
      type: VIEW_EDIT_HIDE
    });
  } catch (error) {}
};
