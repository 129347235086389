import { httpApps } from "../helper/http-common";

class CompleteYourRingService {
  SaveDropHint(inputData) {
    return httpApps.post("studBuilder/SendStudDropHint", inputData);
  }
  ComplateEaringsSendStudDropHint(inputData) {
    return httpApps.post(
      "studBuilder/ComplateEaringsSendStudDropHint",
      inputData
    );
  }
  SaveRequestInfo(inputData) {
    return httpApps.post("studBuilder/StudBuilderRequestMoreInfo", inputData);
  }
  ComplateEaringsStudBuilderRequestMoreInfo(inputData) {
    return httpApps.post(
      "studBuilder/ComplateEaringsStudBuilderRequestMoreInfo",
      inputData
    );
  }
  SaveEmailAFriend(inputData) {
    return httpApps.post("studBuilder/StudBuildersEmailAFriend", inputData);
  }
  ComplateEaringsStudBuilderEmailAFriend(inputData) {
    return httpApps.post(
      "studBuilder/ComplateEaringsStudBuilderEmailAFriend",
      inputData
    );
  }
  SaveScheduleAViewing(inputData) {
    return httpApps.post("studBuilder/StudBuildersScheduleviewing", inputData);
  }

  GetAppPrevData(inputData) {
    return httpApps.post("RingBuilder/GetAppPrevData", inputData);
  }
  StudBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "studBuilder/StudBuilderRequestCertificate",
      inputData
    );
  }
  GetMountByGFId(inputData) {
    return httpApps.post("studBuilder/GetMountByGFId", inputData);
  }
  DiamondPair(inputData) {
    return httpApps.post("studBuilder/DiamondPair", inputData);
  }
  GetStudDropDownData(inputData) {
    return httpApps.post("studBuilder/GetStudDropDownData", inputData);
  }
  ChangeProductConfigurationStud(inputData) {
    return httpApps.post(
      "studBuilder/ChangeProductConfigurationStud",
      inputData
    );
  }
  CompleteYourEarringSocialLinks(inputData) {
    return httpApps.post(
      "studBuilder/CompleteYourEarringSocialLinks",
      inputData
    );
  }
}

export default new CompleteYourRingService();
