import { httpApps } from "../helper/http-common";

class SelectYourDiamoiondServices {
  GetStudBuilderOpeningPage(inputData) {
    return httpApps.post("studBuilder/GetStudBuilderOpeningPage", inputData);
  }
  GetRingBuilderSelectLabDiamond(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderSelectLabDiamond",
      inputData
    );
  }

  GetRingBuilderSelectMinedDiamond(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderSelectMinedDiamond",
      inputData
    );
  }

  GetAvailableStudBuilderFilters(inputData) {
    return httpApps.post(
      "studBuilder/GetAvailableStudBuilderFilters",
      inputData
    );
  }

  SendMailToRetailerForNoResults(inputData) {
    return httpApps.post(
      "RingBuilder/SendMailToRetailerForNoResults",
      inputData
    );
  }
  GetRingBuilderMinedCompareData(inputData) {
    return httpApps.post(
      "RingBuilder/GetRingBuilderMinedCompareData",
      inputData
    );
  }

  GetRingBuilderLabCompareData(inputData) {
    return httpApps.post("RingBuilder/GetRingBuilderLabCompareData", inputData);
  }

  RingBuilderRequestCertificate(inputData) {
    return httpApps.post(
      "RingBuilder/RingBuilderRequestCertificate",
      inputData
    );
  }

  GetDealerInformation(inputData) {
    return httpApps.post("DiamondLink/GetDealerInformation", inputData);
  }
  LoadDiamonds(inputData) {
    return httpApps.post("studBuilder/LoadDiamonds", inputData);
  }
  GetstudBuilderOptiondetails(inputData) {
    return httpApps.post("studBuilder/GetstudBuilderOptiondetails", inputData);
  }
  GetAvailableStudbuilderFilters(inputData) {
    return httpApps.post(
      "studBuilder/GetAvailableStudbuilderFilters",
      inputData
    );
  }
  GetSimilarDiamondsByPage(inputData, pageName) {
    if (pageName == "Mined") {
      return httpApps.post(
        "RingBuilder/GetRingBuilderSelectMinedDiamond",
        inputData
      );
    } else {
      return httpApps.post(
        "RingBuilder/GetRingBuilderSelectLabDiamond",
        inputData
      );
    }
  }
}

export default new SelectYourDiamoiondServices();
